<template>
  <div class="team-member-details">
    <close-button @click="$emit('close')"></close-button>
    <div class="modal-content">
      <h4>{{ member.name }}</h4>
      <div class="member_title">{{ member.title }}</div>
      <img class="member_photo" :src="member.imgSrc" alt="Photo" />
      <div class="details-html" v-html="member.details_html"></div>
    </div>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';

export default {
  props: {
    member: Object,
  },
  name: 'TeamMemberDetails',
  components: {CloseButton},
};
</script>

<style lang="scss" scoped>
.team-member-details {
  img.member_photo {
    display: block;
    float: right;
    max-width: 400px;
    width: 320px;
    margin-left: 20px;
    margin-bottom: 20px;
    box-shadow: $box-shadow;
  }

  .member_title {
    margin-bottom: 20px;
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    img.member_photo {
      width: 100%;
      margin: 0;
      max-width: 100%;
      margin-bottom: 30px;
      float: none;
    }
  }
}
</style>
